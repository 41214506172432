import React from "react";
import HomeWhoWeAre from "./homeWhoWeAre";

function Values({languageState}) {

    return (
        <div style={pageStyle.container}>
            <HomeWhoWeAre language={languageState.language}/>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

export default Values
