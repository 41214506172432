import React, {useRef, useState} from "react";
import MainButton from "../../commons/mainButton";
import {
    colorBookingPaid,
    colorStatus,
    GLOBAL_BACKGROUND_POPUP_ABSOLUTE,
    GLOBAL_BORDER_RADIUS,
    HIGHLIGHT_COLOR,
    orderStatus
} from "../../../costants";
import Loader from "../../commons/loader";
import MyNormalText from "../../commons/myNormalText";
import {formatUnixDate} from "../../../global/dates";
import ItemBox from "../item/itemBox";
import CommonPopup from "../../commons/commonPopup";
import MyIcon from "../../commons/myIcon";
import useMyClickOutListener from "../../../global/listeners/useMyClickOutListener";

function ManualBookingDetail({
                                 rentUser,
                                 premiumPalette,
                                 booking,
                                 loadingBooking,
                                 setIsOpen,
                                 handleSwitchToUpdateManualBooking,
                                 handleDeleteManualBooking
                             }) {

    const primaryColorSecondAlternative = premiumPalette.primaryColorSecondAlternative
    const primaryColor = premiumPalette.primaryColor
    const primaryColorFourthAlternative = premiumPalette.primaryColorFourthAlternative

    const [openPopupDeleteBooking, setOpenPopupDeleteBooking] = useState(false)
    const dateOrderTimeStamp = new Date(booking?.timeStampInsertDate?._seconds * 1000)
    const bookingDetailRef = useRef()

    const isSingleDayRent = booking.isSingleDayRent
    const singleDaySlot = booking.orderDates?.singleDaySlot

    useMyClickOutListener(bookingDetailRef, () => setIsOpen(false))

    function getStatus() {
        if (booking.status === orderStatus.ACCEPTED) {
            return (
                <div style={{...pageStyle.bookingInfo, ...{backgroundColor: colorStatus.ACCEPTED}}}>
                    <MyIcon icon={require("../../../imgs/booking/status.png")}
                            name={'status'}
                            width={pageStyle.imageInfo.width}
                            height={pageStyle.imageInfo.height}/>
                    <div style={pageStyle.columnInfo}>
                        <MyNormalText text={'Stato'}
                                      bold={true}/>
                        <MyNormalText text={'Accettato'}/>
                    </div>
                </div>
            )
        }
    }

    function getOrderDates() {
        if (booking.orderDates.to) {
            return (
                <div
                    style={{...pageStyle.bookingInfo, ...{backgroundColor: primaryColorFourthAlternative}}}>
                    <MyIcon icon={require("../../../imgs/booking/calendar.png")}
                            name={'dates'}
                            width={pageStyle.imageInfo.width}
                            height={pageStyle.imageInfo.height}/>
                    <div style={pageStyle.columnInfo}>
                        <MyNormalText text={'Date richieste'}
                                      bold={true}/>
                        <MyNormalText text={'da ' + booking.orderDates.from + ' a ' + booking.orderDates.to}/>
                    </div>
                </div>
            )
        }

        return (
            <div style={{...pageStyle.bookingInfo, ...{backgroundColor: primaryColorFourthAlternative}}}>
                <MyIcon icon={require("../../../imgs/booking/calendar.png")}
                        name={'dates'}
                        width={pageStyle.imageInfo.width}
                        height={pageStyle.imageInfo.height}/>
                <div style={pageStyle.columnInfo}>
                    <MyNormalText text={'Data richiesta'}
                                  bold={true}/>
                    <MyNormalText text={booking.orderDates.from}/>
                </div>
            </div>
        )
    }

    function createSlotDescription() {
        return singleDaySlot?.from + ' - ' + singleDaySlot?.to + ' (' + singleDaySlot?.description + ')'
    }

    function calculateIsPaidBookingSection() {
        function getPaidStyle() {
            const backgroundColor = booking.isPaid ? colorBookingPaid.YES : colorBookingPaid.NO
            return {...pageStyle.bookingInfo, backgroundColor: backgroundColor}
        }

        return (
            <div style={pageStyle.subHeaderRow}>
                <div style={getPaidStyle()}>
                    <MyIcon icon={require("../../../imgs/booking/paid.png")}
                            name={'Paid'}
                            width={pageStyle.imageInfo.width}
                            height={pageStyle.imageInfo.height}/>
                    <div style={pageStyle.columnInfo}>
                        <MyNormalText text={'Pagato'}
                                      bold={true}/>
                        <MyNormalText text={booking.isPaid ? 'Sì' : 'No'}/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={pageStyle.containerFullPageOpacity}>
            {loadingBooking ?
                <Loader absoluteFullPage={true}/> :
                <div ref={bookingDetailRef}
                     style={pageStyle.container}>
                    <div style={{...pageStyle.booking, backgroundColor: primaryColorSecondAlternative}}>
                        <div style={{...pageStyle.headerRow, backgroundColor: primaryColor}}>
                            <MyNormalText text={'Ordine manuale'}
                                          bold={true}/>
                        </div>
                        {calculateIsPaidBookingSection()}
                        <div style={pageStyle.bookingInfoContainer}>
                            {getStatus()}
                            <div
                                style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                <MyIcon icon={require("../../../imgs/booking/id.png")}
                                        name={'id'}
                                        width={pageStyle.imageInfo.width}
                                        height={pageStyle.imageInfo.height}/>
                                <div style={pageStyle.columnInfo}>
                                    <MyNormalText text={'Codice ordine'}
                                                  bold={true}/>
                                    <MyNormalText text={booking.orderId}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                <MyIcon icon={require("../../../imgs/booking/user.png")}
                                        name={'user'}
                                        width={pageStyle.imageInfo.width}
                                        height={pageStyle.imageInfo.height}/>
                                <div style={pageStyle.columnInfo}>
                                    <MyNormalText text={'Ordine di'}
                                                  bold={true}/>
                                    <MyNormalText text={booking.client.name}/>
                                </div>
                            </div>
                            <div
                                style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                <MyIcon icon={require("../../../imgs/booking/email.png")}
                                        name={'email'}
                                        width={pageStyle.imageInfo.width}
                                        height={pageStyle.imageInfo.height}/>
                                <div style={pageStyle.columnInfo}>
                                    <MyNormalText text={'E-Mail'}
                                                  bold={true}/>
                                    <MyNormalText text={booking.client.email}/>
                                </div>
                            </div>
                            {getOrderDates()}
                            <div style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                <MyIcon icon={require("../../../imgs/booking/send.png")}
                                        name={'senttime'}
                                        width={pageStyle.imageInfo.width}
                                        height={pageStyle.imageInfo.height}/>
                                <div style={pageStyle.columnInfo}>
                                    <MyNormalText text={'Creato il'}
                                                  bold={true}/>
                                    <MyNormalText text={formatUnixDate(dateOrderTimeStamp)}/>
                                </div>
                            </div>
                            {isSingleDayRent ?
                                <div style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                    <MyIcon icon={require("../../../imgs/booking/single-day-rent.png")}
                                            name={'sent time'}
                                            width={pageStyle.imageInfo.width}
                                            height={pageStyle.imageInfo.height}/>
                                    <div style={pageStyle.columnInfo}>
                                        <MyNormalText text={'Fascia oraria'}
                                                      bold={true}/>
                                        <MyNormalText text={createSlotDescription()}/>
                                    </div>
                                </div> :
                                <></>}
                            <div style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                <MyIcon icon={require("../../../imgs/booking/detail.png")}
                                        name={'total'}
                                        width={pageStyle.imageInfo.width}
                                        height={pageStyle.imageInfo.height}/>
                                <div style={pageStyle.columnInfo}>
                                    <MyNormalText text={'Totale'}
                                                  bold={true}/>
                                    <MyNormalText text={'€ ' + booking.total}/>
                                </div>
                            </div>
                        </div>
                        <div style={{...pageStyle.containerNotes, backgroundColor: primaryColorFourthAlternative}}>
                            <MyIcon icon={require("../../../imgs/booking/notes.png")}
                                    name={'notes'}
                                    width={pageStyle.imageInfo.width}
                                    height={pageStyle.imageInfo.height}/>
                            <div style={pageStyle.columnInfo}>
                                <MyNormalText text={'Note'}
                                              bold={true}/>
                                <MyNormalText text={booking.notes}/>
                            </div>
                        </div>
                        <div style={pageStyle.containerItems}>
                            {booking.items?.map(item => {
                                return (
                                    <div key={item.item.id}>
                                        <ItemBox rentUser={rentUser}
                                                 premiumPalette={premiumPalette}
                                                 item={item.item}
                                                 hidePrices={true}
                                                 openOperations={false}/>
                                    </div>
                                )
                            })}
                        </div>
                        {handleDeleteManualBooking && handleSwitchToUpdateManualBooking ?
                            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20, gap: 20}}>
                                <MainButton text={'Modifica ordine'}
                                            action={() => handleSwitchToUpdateManualBooking(booking)}
                                            backgroundColor={premiumPalette.secondaryColor}/>
                                <MainButton text={'Cancella ordine'}
                                            action={() => setOpenPopupDeleteBooking(true)}
                                            backgroundColor={HIGHLIGHT_COLOR}/>

                            </div> :
                            <></>}
                    </div>
                    {openPopupDeleteBooking ?
                        <CommonPopup message={'Sei sicuro di voler cancellare l\'ordine?'}
                                     premiumPalette={premiumPalette}
                                     firstButtonText={'Conferma'}
                                     firstButtonColor={HIGHLIGHT_COLOR}
                                     firstButtonAction={() => handleDeleteManualBooking(booking.bookingId)}
                                     secondButtonText={'Annulla'}
                                     secondButtonColor={primaryColor}
                                     secondButtonAction={() => setOpenPopupDeleteBooking(false)}
                                     setIsOpen={setOpenPopupDeleteBooking}/> :
                        <></>}
                </div>}
        </div>
    )
}

const pageStyle = {
    containerFullPageOpacity: {
        zIndex: 1,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: GLOBAL_BACKGROUND_POPUP_ABSOLUTE,
        overflowY: 'scroll'
    },
    container: {
        position: 'absolute',
        left: '50%',
        top: '5%',
        transform: 'translate(-50%, 0)',
        width: 'calc(100% / 2)',
        zIndex: 1
    },
    booking: {
        padding: 20,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginBottom: 20
    },
    containerItems: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 20,
        gap: 10
    },
    bannerType: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginBottom: 20
    },
    headerRow: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginBottom: 20
    },
    subHeaderRow: {
        display: 'flex',
        marginBottom: 20
    },
    bookingInfoContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
        gap: 10
    },
    bookingInfo: {
        display: 'flex',
        padding: 5,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    containerNotes: {
        display: 'flex',
        padding: 5,
        marginTop: 20,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    actionOnChatStyle: {
        width: 25,
        height: 25,
        marginLeft: 10,
        cursor: 'pointer'
    },
    imageInfo: {
        height: 24,
        width: 24
    },
    columnInfo: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 5
    }
}

export default ManualBookingDetail
