export function removeItemById(array, id) {
    const index = array.findIndex(item => item.id === id)

    if (index !== -1) {
        array.splice(index, 1)
    }
    return array
}

export function isItemInArrayById(array, id) {
    const index = array.findIndex(item => item.id === id)
    return index !== -1

}

export function removeStringFromArray(array, strToRemove) {
    const index = array.indexOf(strToRemove)

    if (index !== -1) {
        array.splice(index, 1)
    }

    return array
}
