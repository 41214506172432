import React from "react";
import ChatDetail from "./chatDetail";
import MainButton from "../../commons/mainButton";
import {GLOBAL_BORDER_RADIUS, GLOBAL_SHADOW_MEDIUM_ACCENTUATED} from "../../../costants";

function ChatFooter({
                        chatFooterRef,
                        premiumPalette,
                        rentUser,
                        activeChats,
                        openChat,
                        handleToggleChat,
                        handleDeleteChat
                    }) {

    const primaryColorFourthAlternative = premiumPalette.primaryColorFourthAlternative
    const primaryColor = premiumPalette.primaryColor

    return (
        <div ref={chatFooterRef}
             style={pageStyle.container}>
            <div style={pageStyle.innerContainer}>
                {activeChats.map(chat => {
                    const openThisChat = chat.chatId === openChat.chatId
                    const backgroundColor = openThisChat ? primaryColorFourthAlternative : primaryColor
                    return (
                        <div key={chat.chatId}
                             style={{margin: 10}}>
                            <ChatDetail rentUser={rentUser}
                                        premiumPalette={premiumPalette}
                                        chat={chat}
                                        handleCloseChat={handleToggleChat}
                                        isActive={chat.chatId === openChat.chatId}/>
                            <div style={{...pageStyle.button, backgroundColor: backgroundColor}}>
                                <MainButton text={chat.body.client.name}
                                            action={() => handleToggleChat(chat)}
                                            backgroundColor={backgroundColor}/>
                                <div>
                                    <MainButton image={require("../../../imgs/actions/remove.png")}
                                                action={() => handleDeleteChat(chat)}
                                                backgroundColor={backgroundColor}
                                                imageWidth={15}
                                                imageHeight={15}/>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        position: 'fixed',
        bottom: 0,
        left: 0
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        flexWrap: 'wrap'
    },
    button: {
        display: 'flex',
        minWidth: 160,
        borderRadius: GLOBAL_BORDER_RADIUS,
        boxShadow: GLOBAL_SHADOW_MEDIUM_ACCENTUATED
    }
}

export default ChatFooter
