import React, {useState} from "react";
import {
    colorBookingPaid,
    DISABLED,
    EURO_VALUE,
    GLOBAL_BORDER_RADIUS,
    HIGHLIGHT_COLOR,
    INFO_COLOR,
    REPLACEMENT_ITEM_COLOR,
    SIZES
} from "../../../costants";
import {globalElementInputStyle} from "../../../global/style/globalElementInputStyle";
import MyNormalText from "../../commons/myNormalText";
import MainButton from "../../commons/mainButton";
import ItemBox from "../item/itemBox";
import Loader from "../../commons/loader";
import ButtonsToOrderItems from "../premium/buttonsToOrderItems";
import {calculateTotal} from "../../../global/totalFunctions";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, {registerLocale} from "react-datepicker";
import it from "date-fns/locale/it";
import "../../../css/myDatePicker.css";
import {toMomentDate} from "../../../global/dates";
import useMyEscapeListener from "../../../global/listeners/useMyEscapeListener";
import MyIcon from "../../commons/myIcon";
import {isItemInArrayById, removeItemById, removeStringFromArray} from "../../../global/arrays";

registerLocale("it", it);

const FIRST_SECTION = 1
const SECOND_SECTION = 2
const THIRD_SECTION = 3
const FOURTH_SECTION = 4

const ITEM_ADDED_FILTER = 'ITEM_ADDED_FILTER'

function BookingPopupCreator({
                                 rentUser,
                                 premiumPalette,
                                 isSystemAutomaticBooking,
                                 orderDates,
                                 rangeSelected,
                                 handleSelectRange,
                                 clientName,
                                 setClientName,
                                 initialStringValues,
                                 initialSlotValue,
                                 initialStateCustomTimeSlot,
                                 clientEmail,
                                 setClientEmail,
                                 notes,
                                 setNotes,
                                 slotSelected,
                                 customTimeSlotFrom,
                                 customTimeSlotTo,
                                 handleChangeCustomSlotTimeFrom,
                                 handleChangeCustomSlotTimeTo,
                                 handleSelectSlot,
                                 total,
                                 isPaid,
                                 setIsPaid,
                                 handleUpdateItemTotal,
                                 itemsToAdd,
                                 setItemsToAdd,
                                 canCreateNewBooking,
                                 itemsToSelectFrom,
                                 setNewBookingItems,
                                 loadingAvailableItems,
                                 handleInsertOrUpdateNewBooking,
                                 theUserIsUpdatingABooking,
                                 handleCloseBookingCreator
                             }) {

    const thisIsASingleDayRent = orderDates.from && !!!orderDates.to
    const rentSlotConfiguration = rentUser.rentSlotConfiguration
    const primaryColor = premiumPalette.primaryColor
    const secondaryColor = premiumPalette.secondaryColor
    const primaryColorFirstAlternative = premiumPalette.primaryColorFirstAlternative

    const [currentSection, setCurrentSection] = useState(FIRST_SECTION)
    const [replacementItemIds, setReplacementItemIds] = useState(itemsToAdd.filter(item => item.isReplacementItem).map(item => item.id))
    const [itemFilters, setItemFilters] = useState([])

    const notesAreaStyle = {...globalElementInputStyle.customInput, width: '100%', height: '120px', resize: 'none'}

    useMyEscapeListener(() => handleCloseBookingCreator())

    function handleAddOrRemoveItem(itemToAddOrDelete) {
        const itemId = itemToAddOrDelete.id
        if (!isItemInArrayById(itemsToAdd, itemToAddOrDelete.id)) {
            const totalForThisItem = calculateTotal(itemToAddOrDelete, slotSelected, orderDates)
            itemsToAdd.push({
                ...itemToAddOrDelete,
                total: totalForThisItem,
                isReplacementItem: isSystemAutomaticBooking
            })
            if (isSystemAutomaticBooking) {
                replacementItemIds.push(itemId)
            }
        } else {
            if (!isSystemAutomaticBooking) {
                removeItemById(itemsToAdd, itemId)
            } else if (replacementItemIds.includes(itemId)) {
                removeItemById(itemsToAdd, itemId)
                removeStringFromArray(replacementItemIds, itemId)
            }
        }
        setItemsToAdd([...itemsToAdd])
        setReplacementItemIds([...replacementItemIds])
    }

    function getOrderDates() {
        if (!orderDates.from) {
            return <MyNormalText text={'Inserisci le date'}/>
        }

        if (orderDates.to) {
            return <MyNormalText text={'Da ' + orderDates?.from + ' a ' + orderDates?.to + ' compresi'}/>
        }
        return <MyNormalText text={'Per il giorno ' + orderDates?.from}/>
    }

    function handleInsertClientName(name) {
        if (name?.length > 15) {
            return
        }
        setClientName(name)
    }

    function handleSelectStartDate(date) {
        return handleSelectRange({start: date, end: date})
    }

    function handleSelectEndDate(date) {
        if (toMomentDate(date).isBefore(rangeSelected[0]?.start)) {
            return handleSelectRange({start: date, end: date})
        } else if (rangeSelected[0]?.start) {
            return handleSelectRange({start: rangeSelected[0]?.start, end: date})
        }
        return handleSelectRange({start: date, end: date})
    }

    function handleInsertTotal(item, total) {
        if (total) {
            handleUpdateItemTotal(item, parseInt(total))
        } else {
            handleUpdateItemTotal(item, undefined)
        }
    }

    function cantGoToSecondSection(sectionToGo) {
        return sectionToGo === SECOND_SECTION && (rangeSelected.length === 0 || (rangeSelected.length > 0 && (thisIsASingleDayRent && (slotSelected === initialSlotValue && customTimeSlotFrom === initialStateCustomTimeSlot))))
    }

    function cantGoToThirdSection(sectionToGo) {
        return sectionToGo === THIRD_SECTION && itemsToAdd.length === 0
    }

    function cantGoToFourthSection(sectionToGo) {
        return cantGoToSecondSection(sectionToGo) || cantGoToThirdSection(sectionToGo) || (sectionToGo === FOURTH_SECTION && clientName === initialStringValues)
    }

    function changeSection(sectionToGo) {
        if (cantGoToSecondSection(sectionToGo) || cantGoToThirdSection(sectionToGo) || cantGoToFourthSection(sectionToGo)) {
            return
        }
        setCurrentSection(sectionToGo)
    }

    function sectionDisable(sectionToGo) {
        if (currentSection === sectionToGo) {
            return false
        }
        return !!(cantGoToSecondSection(sectionToGo) || cantGoToThirdSection(sectionToGo) || cantGoToFourthSection(sectionToGo))
    }

    function goBack() {
        if (currentSection === FIRST_SECTION) {
            return
        }
        setCurrentSection(currentSection - 1)
    }

    function canGoBack() {
        return currentSection > FIRST_SECTION
    }

    function goAhead() {
        if (currentSection === FOURTH_SECTION) {
            return
        } else if (currentSection === FIRST_SECTION && cantGoToSecondSection(SECOND_SECTION)) {
            return
        } else if (currentSection === SECOND_SECTION && cantGoToThirdSection(THIRD_SECTION)) {
            return
        } else if (currentSection === THIRD_SECTION && cantGoToFourthSection(FOURTH_SECTION)) {
            return
        }
        setCurrentSection(currentSection + 1)
    }

    function canGoAhead() {
        const sectionToGo = currentSection + 1
        if (sectionToGo > FOURTH_SECTION) {
            return false
        } else if (currentSection === FIRST_SECTION && cantGoToSecondSection(sectionToGo)) {
            return false
        } else if (currentSection === SECOND_SECTION && cantGoToThirdSection(sectionToGo)) {
            return false
        } else if (currentSection === THIRD_SECTION && cantGoToFourthSection(sectionToGo)) {
            return false
        }
        return true
    }

    function calculateClientNameInputStyle() {
        if (initialStringValues === clientName) {
            return {...globalElementInputStyle.customInput, ...{border: '3px solid ' + HIGHLIGHT_COLOR}}
        }
        return globalElementInputStyle.customInput
    }

    function calculateBackgroundColor(actualSection) {
        return currentSection === actualSection ? secondaryColor : primaryColor
    }

    function calculateAddOrRemoveItemButtonColor(itemFound, item) {
        if (isSystemAutomaticBooking) {
            if (itemFound) {
                if (replacementItemIds.includes(item.id)) {
                    return HIGHLIGHT_COLOR
                }
                return DISABLED
            }
            return secondaryColor
        } else {
            return itemFound ? HIGHLIGHT_COLOR : secondaryColor
        }
    }

    function handleAddItemFilter(filter) {
        if (itemFilters.includes(filter)) {
            removeStringFromArray(itemFilters, filter)
        } else {
            itemFilters.push(filter)
        }
        setItemFilters([...itemFilters])
    }

    return (
        <div style={pageStyle.containerFullPageOpacity}>
            <div style={{...pageStyle.centerContainer, backgroundColor: primaryColor}}>
                {isSystemAutomaticBooking ?
                    <div style={pageStyle.infoSection}>
                        <MyIcon icon={require('../../../imgs/actions/info.png')}
                                height={40}
                                width={40}
                                name={'info'}/>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <MyNormalText text={'Questo è un ordine LoceM'}
                                          bold={true}/>
                            <MyNormalText text={'- Puoi aggiungere articoli sostitutivi e modificare le note'}/>
                            <MyNormalText text={'- Il totale dell\'ordine non cambierà'}/>
                            <MyNormalText text={'- L\' utente non riceverà notifiche'}/>
                        </div>
                    </div> :
                    <></>}
                <div style={{...pageStyle.headerSection, backgroundColor: primaryColorFirstAlternative}}>
                    <MyNormalText text={'Crea un nuovo ordine'}
                                  bold={true}
                                  fontSize={20}/>
                    <div style={pageStyle.menuIndicatorSectionContainer}>
                        <div>
                            <MainButton text={'1. Per quando'}
                                        action={() => changeSection(FIRST_SECTION)}
                                        textUnderlain={currentSection === FIRST_SECTION}
                                        backgroundColor={calculateBackgroundColor(FIRST_SECTION)}
                                        borderRadius={50}
                                        paddingHorizontal={40}
                                        disabled={sectionDisable(FIRST_SECTION)}/>
                        </div>
                        <div>
                            <MainButton text={'2. Aggiungi articoli'}
                                        action={() => changeSection(SECOND_SECTION)}
                                        textUnderlain={currentSection === SECOND_SECTION}
                                        backgroundColor={calculateBackgroundColor(SECOND_SECTION)}
                                        borderRadius={50}
                                        paddingHorizontal={40}
                                        disabled={sectionDisable(SECOND_SECTION)}/>
                        </div>
                        <div>
                            <MainButton text={'3. Informazioni cliente'}
                                        action={() => changeSection(THIRD_SECTION)}
                                        textUnderlain={currentSection === THIRD_SECTION}
                                        backgroundColor={calculateBackgroundColor(THIRD_SECTION)}
                                        borderRadius={50}
                                        paddingHorizontal={40}
                                        disabled={sectionDisable(THIRD_SECTION)}/>
                        </div>
                        <div>
                            <MainButton text={'4. Riepilogo'}
                                        action={() => changeSection(FOURTH_SECTION)}
                                        textUnderlain={currentSection === FOURTH_SECTION}
                                        backgroundColor={calculateBackgroundColor(FOURTH_SECTION)}
                                        borderRadius={50}
                                        paddingHorizontal={40}
                                        disabled={sectionDisable(FOURTH_SECTION)}/>
                        </div>
                    </div>
                </div>
                {currentSection === FIRST_SECTION ?
                    <>
                        <div style={{...pageStyle.newBookingSection, backgroundColor: primaryColorFirstAlternative}}>
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{marginRight: 10}}>
                                        <MyNormalText text={'Da'}/>
                                    </div>
                                    <DatePicker selected={rangeSelected[0]?.start}
                                                onChange={date => handleSelectStartDate(date)}
                                                disabled={isSystemAutomaticBooking}
                                                showMonthYearDropdown={false}
                                                className={'custom-input'}
                                                dateFormat={'dd/MM/yyyy'}
                                                locale={'it'}
                                                placeholderText={'Data inizio'}/>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{marginLeft: 10, marginRight: 10}}>
                                        <MyNormalText text={'a'}/>
                                    </div>
                                    <DatePicker selected={rangeSelected[0]?.end}
                                                onChange={date => handleSelectEndDate(date)}
                                                disabled={isSystemAutomaticBooking}
                                                className={'custom-input'}
                                                showMonthYearDropdown={false}
                                                dateFormat={'dd/MM/yyyy'}
                                                locale={'it'}
                                                placeholderText={'Data fine'}/>
                                </div>
                            </div>
                        </div>
                        {thisIsASingleDayRent ?
                            <div style={{display: 'grid', gap: 10, marginTop: 10}}>
                                <div style={{
                                    ...pageStyle.newBookingSection,
                                    flex: 1,
                                    backgroundColor: primaryColorFirstAlternative
                                }}>
                                    <div style={pageStyle.marginBottom10}>
                                        <MyNormalText text={'Seleziona lo slot'}
                                                      bold={true}/>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap'}}>
                                        {rentSlotConfiguration.map((slot, index) => {
                                            const slotContainerStyle = {
                                                ...pageStyle.slotContainer,
                                                backgroundColor: primaryColor,
                                                cursor: thisIsASingleDayRent ? 'pointer' : undefined
                                            }

                                            const disabled = !thisIsASingleDayRent || isSystemAutomaticBooking

                                            function handleSelectSlotWithGuard() {
                                                if (disabled) {
                                                    return
                                                }
                                                return handleSelectSlot(slot)
                                            }

                                            return (
                                                <div key={index.toString()}
                                                     onClick={handleSelectSlotWithGuard}
                                                     style={slotContainerStyle}>
                                                    <MyNormalText
                                                        text={slot.from + ' - ' + slot.to + ' (' + slot.description + ')'}/>
                                                    <input type={'checkbox'}
                                                           disabled={disabled}
                                                           style={{width: 16, height: 16}}
                                                           checked={slot.type === slotSelected?.type}
                                                           onChange={handleSelectSlotWithGuard}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div style={{
                                    ...pageStyle.newBookingSection,
                                    flex: 1,
                                    backgroundColor: primaryColorFirstAlternative
                                }}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div style={pageStyle.marginBottom10}>
                                            <MyNormalText text={'Oppure seleziona l\'orario'}
                                                          bold={true}/>
                                        </div>
                                        <div style={pageStyle.clockPickerContainer}>
                                            <div style={pageStyle.containerHoursAndMinutesInput}>
                                                <div style={{marginRight: 5}}>
                                                    <MyNormalText text={'Dalle'}/>
                                                </div>
                                                <input id={'hoursInputFrom'}
                                                       type={'time'}
                                                       value={customTimeSlotFrom}
                                                       style={pageStyle.inputHourAndMinutes}
                                                       disabled={!thisIsASingleDayRent || isSystemAutomaticBooking}
                                                       onChange={(e) => handleChangeCustomSlotTimeFrom(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div style={{...pageStyle.clockPickerContainer, marginTop: 10}}>
                                            <div style={pageStyle.containerHoursAndMinutesInput}>
                                                <div style={{marginRight: 5}}>
                                                    <MyNormalText text={'Alle'}/>
                                                </div>
                                                <input id={'hoursInputTo'}
                                                       type={'time'}
                                                       value={customTimeSlotTo}
                                                       style={pageStyle.inputHourAndMinutes}
                                                       disabled={!thisIsASingleDayRent || isSystemAutomaticBooking}
                                                       onChange={(e) => handleChangeCustomSlotTimeTo(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <></>}
                    </> :
                    <></>}
                {currentSection === SECOND_SECTION ?
                    <div style={{...pageStyle.newBookingSection, backgroundColor: primaryColorFirstAlternative}}>
                        <div style={pageStyle.filtersContainer}>
                            <MyNormalText text={'Filtra per'}/>
                            <div style={pageStyle.filtersInnerContainer}>
                                {rentUser.categories.map((category, index) => {
                                    return (
                                        <div key={index}>
                                            <MainButton text={category}
                                                        action={() => handleAddItemFilter(category)}
                                                        backgroundColor={itemFilters.includes(category) ? secondaryColor : primaryColor}
                                                        height={35}
                                                        borderRadius={20}/>
                                        </div>
                                    )
                                })}
                            </div>
                            <div style={pageStyle.filtersInnerContainer}>
                                {Object.keys(SIZES).map((size, index) => {
                                    return (
                                        <div key={index}>
                                            <MainButton text={size}
                                                        action={() => handleAddItemFilter(size)}
                                                        backgroundColor={itemFilters.includes(size) ? secondaryColor : primaryColor}
                                                        height={35}
                                                        borderRadius={20}/>
                                        </div>
                                    )
                                })}
                            </div>
                            <div style={pageStyle.filtersInnerContainer}>
                                <div>
                                    <MainButton text={'Aggiunti nell\'ordine'}
                                                action={() => handleAddItemFilter(ITEM_ADDED_FILTER)}
                                                backgroundColor={itemFilters.includes(ITEM_ADDED_FILTER) ? secondaryColor : primaryColor}
                                                height={35}
                                                borderRadius={20}/>
                                </div>
                            </div>
                        </div>
                        <ButtonsToOrderItems items={itemsToSelectFrom}
                                             premiumPalette={premiumPalette}
                                             setItems={setNewBookingItems}
                                             isAssignedRentCodeAsc={true}
                                             buttonsColor={primaryColor}/>
                        <div style={pageStyle.itemsAvailableContainerOnY}>
                            {loadingAvailableItems ?
                                <Loader/> :
                                itemsToSelectFrom.filter(item => {
                                    if (itemFilters.length === 0) {
                                        return true
                                    }
                                    const itemInThoseToAdd = isItemInArrayById(itemsToAdd, item.id)
                                    return itemFilters.includes(item.category) ||
                                        itemFilters.includes(item.size) ||
                                        (itemFilters.includes(ITEM_ADDED_FILTER) ? itemInThoseToAdd : false)
                                }).map(item => {
                                    const itemInThoseToAdd = isItemInArrayById(itemsToAdd, item.id)
                                    return (
                                        <div key={item.id}
                                             style={{...pageStyle.itemContainerToAdd, backgroundColor: primaryColor}}>
                                            <div style={{position: 'relative', flex: 3, marginBottom: 10}}>
                                                <div style={pageStyle.itemsTagContainer}>
                                                    {itemInThoseToAdd ?
                                                        <div style={{...pageStyle.itemInThoseToAddTag, backgroundColor: primaryColor}}>
                                                            <MyNormalText text={'Aggiunto'}
                                                                          fontSize={14}/>
                                                        </div> :
                                                        <></>}
                                                    {replacementItemIds.includes(item.id) ?
                                                        <div style={pageStyle.replacementTagItem}>
                                                            <MyNormalText text={'Sostitutivo'}
                                                                          fontSize={14}/>
                                                        </div> :
                                                        <></>}
                                                </div>
                                                <ItemBox rentUser={rentUser}
                                                         premiumPalette={premiumPalette}
                                                         item={item}
                                                         imageHeight={100}
                                                         hidePrices={true}
                                                         openOperations={false}/>
                                            </div>
                                            <MainButton
                                                image={itemInThoseToAdd ? require("../../../imgs/actions/remove.png") : require("../../../imgs/actions/add.png")}
                                                action={() => handleAddOrRemoveItem(item)}
                                                hideDisabledColor={true}
                                                height={30}
                                                imageHeight={18}
                                                imageWidth={18}
                                                backgroundColor={calculateAddOrRemoveItemButtonColor(itemInThoseToAdd, item)}/>
                                        </div>
                                    )
                                })}
                        </div>
                    </div> :
                    <></>}
                {currentSection === THIRD_SECTION ?
                    <div style={pageStyle.containerInfoClient}>
                        <div style={{
                            ...pageStyle.newBookingSection,
                            flex: 1,
                            backgroundColor: primaryColorFirstAlternative
                        }}>
                            <div style={pageStyle.marginBottom10}>
                                <MyNormalText text={'Nome (massimo 15 caratteri)'}
                                              bold={true}/>
                                <input style={calculateClientNameInputStyle()}
                                       type={'text'}
                                       disabled={isSystemAutomaticBooking}
                                       value={clientName}
                                       placeholder={'Mario Rossi'}
                                       onChange={(e) => handleInsertClientName(e.target.value)}/>
                            </div>
                            <div>
                                <MyNormalText text={'Email'}
                                              bold={true}/>
                                <input style={globalElementInputStyle.customInput}
                                       type={'text'}
                                       disabled={isSystemAutomaticBooking}
                                       value={clientEmail}
                                       placeholder={'mario.rossi@email.it'}
                                       onChange={(e) => setClientEmail(e.target.value)}/>
                            </div>
                        </div>
                        <div style={{
                            ...pageStyle.newBookingSection,
                            flex: 1,
                            backgroundColor: primaryColorFirstAlternative
                        }}>
                            <MyNormalText text={'Note'}
                                          bold={true}/>
                            <textarea style={notesAreaStyle}
                                      value={notes}
                                      placeholder={'Numero di telefono, orario di arrivo...'}
                                      onChange={(e) => setNotes(e.target.value)}/>
                        </div>
                    </div> :
                    <></>}
                {currentSection === FOURTH_SECTION ?
                    <>
                        <div style={pageStyle.summaryInfo}>
                            <div style={{
                                ...pageStyle.newBookingSummarySection,
                                backgroundColor: primaryColorFirstAlternative
                            }}>
                                <MyNormalText text={'Date'}
                                              bold={true}/>
                                {getOrderDates()}
                            </div>
                            <div style={{
                                ...pageStyle.newBookingSummarySection,
                                backgroundColor: primaryColorFirstAlternative
                            }}>
                                <MyNormalText text={'Cliente'}
                                              bold={true}/>
                                <MyNormalText text={'Nome: ' + clientName}/>
                                <MyNormalText
                                    text={'Email: ' + (clientEmail === initialStringValues ? '-' : clientEmail)}/>
                            </div>
                            <div style={{
                                ...pageStyle.newBookingSummarySection,
                                backgroundColor: primaryColorFirstAlternative
                            }}>
                                <MyNormalText text={'Note'}
                                              bold={true}/>
                                <MyNormalText text={notes === initialStringValues ? '-' : notes}/>
                            </div>
                            <div>
                                <div style={{
                                    ...pageStyle.newBookingSummarySection,
                                    backgroundColor: primaryColorFirstAlternative
                                }}>
                                    <MyNormalText text={'Totale'}
                                                  bold={true}/>
                                    <MyNormalText text={EURO_VALUE + ' ' + total}/>
                                </div>
                                <div style={{
                                    ...pageStyle.newBookingSummarySection,
                                    backgroundColor: primaryColorFirstAlternative,
                                    marginTop: 10
                                }}>
                                    <MainButton text={isPaid ? 'Sì' : 'No'}
                                                action={() => setIsPaid(!isPaid)}
                                                disabled={isSystemAutomaticBooking}
                                                backgroundColor={isPaid ? colorBookingPaid.YES : colorBookingPaid.NO}
                                                firstText={'Pagato?'}/>
                                </div>
                            </div>
                        </div>
                        <div style={pageStyle.itemsAddedContainer}>
                            {itemsToAdd.length === 0 ?
                                <MyNormalText color={'red'}
                                              text={'Nessun articolo ancora aggiunto all\'ordine'}/> :
                                itemsToAdd.map(item => {
                                    return (
                                        <div key={item.id}>
                                            <ItemBox rentUser={rentUser}
                                                     premiumPalette={premiumPalette}
                                                     item={item}
                                                     blockOpenDetail={true}
                                                     itemNameOverflow={true}
                                                     backgroundColor={primaryColorFirstAlternative}
                                                     imageHeight={120}
                                                     width={200}
                                                     hidePrices={true}
                                                     injectFirstElement={
                                                         isSystemAutomaticBooking ?
                                                             <></> :
                                                             <input style={pageStyle.totalInput}
                                                                    onChange={(e) => handleInsertTotal(item, e.target.value)}
                                                                    disabled={isSystemAutomaticBooking}
                                                                    type={'number'}
                                                                    min={'1'}
                                                                    value={item?.total}/>}/>
                                        </div>
                                    )
                                })}
                        </div>
                        <div style={{marginTop: 20}}>
                            <MainButton text={theUserIsUpdatingABooking ? 'Aggiorna' : 'Inserisci'}
                                        action={() => handleInsertOrUpdateNewBooking()}
                                        fontSize={18}
                                        height={55}
                                        disabled={!canCreateNewBooking}
                                        backgroundColor={secondaryColor}/>
                        </div>
                    </> :
                    <></>}
                <div style={{position: 'fixed', top: 30, right: 30}}>
                    <MainButton image={require("../../../imgs/actions/remove.png")}
                                imageHeight={35}
                                imageWidth={35}
                                action={handleCloseBookingCreator}/>
                </div>
                {canGoBack() ?
                    <div style={{position: 'fixed', top: '50%', left: 60}}>
                        <MainButton image={require("../../../imgs/actions/arrow-left.png")}
                                    action={goBack}
                                    imageHeight={35}
                                    imageWidth={35}/>
                    </div> :
                    <></>}
                {canGoAhead() ?
                    <div style={{position: 'fixed', top: '50%', right: 60}}>
                        <MainButton image={require("../../../imgs/actions/arrow-right.png")}
                                    action={goAhead}
                                    imageHeight={35}
                                    imageWidth={35}/>
                    </div> :
                    <></>}
            </div>
        </div>
    )
}

const pageStyle = {
    containerFullPageOpacity: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1
    },
    centerContainer: {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        paddingLeft: 'calc(100% / 6)',
        paddingRight: 'calc(100% / 6)',
        paddingTop: 40,
        paddingBottom: 40,
        gap: 20
    },
    headerSection: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    infoSection: {
        display: 'flex',
        alignItems: 'center',
        padding: 10,
        gap: 10,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: INFO_COLOR
    },
    newBookingSection: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    containerInfoClient: {
        display: 'grid',
        gap: 10,
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))'
    },
    newBookingSummarySection: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    menuIndicatorSectionContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 10,
        marginTop: 10
    },
    marginBottom10: {
        marginBottom: 10
    },
    clockPickerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        marginBottom: 30
    },
    filtersInnerContainer: {
        display: 'flex',
        gap: 10
    },
    slotContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        marginBottom: 10,
        marginRight: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    replacementTagItem: {
        padding: 2,
        backgroundColor: REPLACEMENT_ITEM_COLOR,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    itemsTagContainer: {
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        right: 0,
        zIndex: 1
    },
    itemInThoseToAddTag: {
        padding: 1,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    itemsAvailableContainerOnY: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 5
    },
    summaryInfo: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        gap: 10
    },
    itemsAddedContainer: {
        display: 'flex',
        overflowX: 'auto',
        gap: 10,
        marginTop: 20,
        paddingBottom: 10
    },
    itemContainerToAdd: {
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginTop: 20,
        marginRight: 10
    },
    totalInput: {
        marginTop: 5,
        border: 0,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: 'white',
        padding: 5,
        fontSize: 16,
        color: 'black',
        outline: 'none',
        boxSizing: 'border-box'
    },
    containerHoursAndMinutesInput: {
        display: 'flex',
        alignItems: 'center'
    },
    inputHourAndMinutes: {
        border: 0,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: 'white',
        padding: 2,
        fontSize: 18,
        color: "#333",
        outline: "none",
        boxSizing: "border-box",
    }
}

export default BookingPopupCreator
