import React from 'react';
import {useLocation} from "react-router-dom";
import {websitePages} from "../../../costants";

function SlidingDiagonalsSlowEffect() {

    const location = useLocation()
    if (location.pathname.includes(websitePages.DASHBOARD)) {
        return null
    }

    const bgStyle = {
        backgroundImage: 'linear-gradient(-60deg, #82E0AA 50%, #B8E986 50%)',
        bottom: 0,
        left: '-50%',
        opacity: 0.5,
        position: 'fixed',
        right: '-50%',
        top: 0,
        zIndex: -1
    }

    const bgVariants = (duration) => ({
        ...bgStyle,
        animation: `slide ${duration}s linear infinite`,
        animationDuration: `${duration}s`
    })

    const styles = `
        @keyframes slide {
            0% {
                transform: translateX(-25%);
            }
            100% {
                transform: translateX(25%);
            }
        }
    `

    if (typeof document !== 'undefined') {
        const styleElement = document.createElement('style')
        styleElement.textContent = styles
        document.head.appendChild(styleElement)
    }

    return (
        <>
            <div style={bgVariants(10)}></div>
            <div style={bgVariants(20, 'alternate-reverse')}></div>
            <div style={bgVariants(30)}></div>
        </>
    )
}

export default SlidingDiagonalsSlowEffect;
