import React from "react";
import MyNormalText from "../../commons/myNormalText";
import MainButton from "../../commons/mainButton";
import {websitePages} from "../../../costants";
import {useLocation, useNavigate} from "react-router-dom";
import {currentYear} from "../../../global/dates";

function Footer({language}) {

    const navigate = useNavigate()
    const location = useLocation()

    if (location.pathname.includes(websitePages.DASHBOARD)) {
        return null
    }

    const pageStyle = {
        container: {
            display: 'flex',
            backgroundColor: 'white',
            alignItems: 'flex-start',
            justifyContent: 'center',
            padding: 20
        },
        footer: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: 60
        },
        footerSection: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        footerSubSection: {
            display: 'flex'
        }
    }

    return (
        <div style={pageStyle.container}>
            <div style={{display: 'flex', flexDirection: 'column', gap: 60}}>
                <div style={pageStyle.footer}>
                    <div style={pageStyle.footerSection}>
                        <MyNormalText text={'Links'}
                                      bold={true}
                                      fontSize={22}/>
                        <div style={pageStyle.footerSubSection}>
                            <MainButton text={'Privacy Policy'}
                                        paddingHorizontal={1}
                                        buttonHoverDisabled={true}
                                        textUnderlain={true}
                                        textAlignStart={true}
                                        action={() => navigate('/app/locem-website/privacy-policy')}/>
                        </div>
                        <div style={pageStyle.footerSubSection}>
                            <MainButton text={'Cookies Policy'}
                                        paddingHorizontal={1}
                                        buttonHoverDisabled={true}
                                        textUnderlain={true}
                                        textAlignStart={true}
                                        action={() => navigate('/app/locem-website/cookies-policy')}/>
                        </div>
                        <div style={pageStyle.footerSubSection}>
                            <MainButton text={'footer.contacts'}
                                        paddingHorizontal={1}
                                        buttonHoverDisabled={true}
                                        textUnderlain={true}
                                        isTranslated={true}
                                        language={language}
                                        textAlignStart={true}
                                        action={() => navigate('/contacts')}/>
                        </div>
                        <div style={pageStyle.footerSubSection}>
                            <MainButton text={'footer.legal.notes'}
                                        paddingHorizontal={1}
                                        buttonHoverDisabled={true}
                                        textUnderlain={true}
                                        isTranslated={true}
                                        language={language}
                                        textAlignStart={true}
                                        action={() => navigate('/legal-notes')}/>
                        </div>
                    </div>
                    <div style={pageStyle.footerSection}>
                        <MyNormalText text={'Socials'}
                                      bold={true}
                                      fontSize={22}/>
                        <div style={pageStyle.footerSubSection}>
                            <MainButton text={'Facebook'}
                                        action={() => window.open('https://www.facebook.com/profile.php?id=61556466463198', '_blank')}
                                        textUnderlain={true}
                                        paddingHorizontal={1}
                                        textAlignStart={true}
                                        buttonHoverDisabled={true}/>
                        </div>
                        <div style={pageStyle.footerSubSection}>
                            <MainButton text={'Instagram'}
                                        action={() => window.open('https://www.instagram.com/locemsolutions/', '_blank')}
                                        textUnderlain={true}
                                        paddingHorizontal={1}
                                        textAlignStart={true}
                                        buttonHoverDisabled={true}/>
                        </div>
                        <div style={pageStyle.footerSubSection}>
                            <MainButton text={'LinkedIn'}
                                        action={() => window.open('https://www.linkedin.com/company/locem-solutions', '_blank')}
                                        textUnderlain={true}
                                        paddingHorizontal={1}
                                        textAlignStart={true}
                                        buttonHoverDisabled={true}/>
                        </div>
                    </div>
                </div>
                <div style={{...pageStyle.footerSubSection, flexDirection: 'column'}}>
                    <MyNormalText text={'P.IVA 04967430234'}/>
                    <MyNormalText text={'Copyright © ' + currentYear() + ' LoceM Solutions'}/>
                </div>
            </div>
        </div>
    )
}

export default Footer
